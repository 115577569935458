import { cn } from '@/web/libs/utils'
import React, { type HTMLAttributes, type ReactNode } from 'react'

export type MarqueeProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode
  direction?: 'left' | 'up'
  pauseOnHover?: boolean
  reverse?: boolean
  fade?: boolean
  gapSize?: 'sm' | 'md' | 'lg'
  innerClassName?: string
  numberOfCopies?: number
  itemsPerMinute?: number
}

export function Marquee({
  children,
  direction = 'left',
  pauseOnHover = false,
  reverse = false,
  fade = false,
  className,
  innerClassName,
  gapSize = 'md',
  numberOfCopies = 2,
  itemsPerMinute = 20,
  ...rest
}: MarqueeProps) {
  const itemCount = React.Children.count(children)
  const duration = (60 / itemsPerMinute) * itemCount
  return (
    <div
      className={cn(
        'group flex overflow-hidden ',
        direction === 'left' ? 'flex-row' : 'flex-col',
        gapSize === 'sm' && 'gap-[1rem]',
        gapSize === 'md' && 'gap-[2rem]',
        gapSize === 'lg' && 'gap-[4rem]',
        className
      )}
      style={{
        maskImage: fade
          ? `linear-gradient(${
              direction === 'left' ? 'to right' : 'to bottom'
            }, transparent 0%, rgba(0, 0, 0, 1.0) 10%, rgba(0, 0, 0, 1.0) 90%, transparent 100%)`
          : undefined,
        WebkitMaskImage: fade
          ? `linear-gradient(${
              direction === 'left' ? 'to right' : 'to bottom'
            }, transparent 0%, rgba(0, 0, 0, 1.0) 10%, rgba(0, 0, 0, 1.0) 90%, transparent 100%)`
          : undefined,
      }}
      {...rest}>
      {Array(numberOfCopies)
        .fill(0)
        .map((_, i) => (
          <div
            key={i}
            className={cn(
              'flex justify-around shrink-0 ',
              direction === 'left'
                ? 'animate-marquee-left flex-row'
                : 'animate-marquee-up flex-col',
              pauseOnHover && 'group-hover:[animation-play-state:paused]',
              reverse && 'direction-reverse',
              gapSize === 'sm' && 'gap-[1rem] [--gap:1rem]',
              gapSize === 'md' && 'gap-[2rem] [--gap:2rem]',
              gapSize === 'lg' && 'gap-[4rem] [--gap:4rem]',
              innerClassName
            )}
            style={
              {
                '--duration': `${duration}s`,
              } as React.CSSProperties
            }>
            {children}
          </div>
        ))}
    </div>
  )
}
