export const LandingHeading = ({
  title,
  subtitle,
}: {
  title: string
  subtitle: string
}) => {
  return (
    <div className='mx-auto max-w-xl text-center px-6 lg:px-8'>
      <h5 className='text-gradient'>{title}</h5>
      <h3 className='mt-2 text-secondary-foreground'>{subtitle}</h3>
    </div>
  )
}
