import { BRAND_LIGHT_COLORS } from '@/shared/constants/color'
import type { Testimonial } from '@/web/constants/landing'

export const TestimonialCard = ({
  testimonial,
  index,
}: {
  testimonial: Testimonial
  index: number
}) => {
  return (
    <figure
      key={testimonial.author}
      className='w-80 py-8 px-4 flex h-full flex-col justify-between rounded-2xl bg-gray-50 text-sm leading-6 gap-8'
      style={{
        backgroundColor: BRAND_LIGHT_COLORS[index % BRAND_LIGHT_COLORS.length],
      }}>
      <blockquote className='text-gray-900 min-h-24'>
        <p className='font-light'>“{testimonial.body}”</p>
      </blockquote>
      <figcaption className='flex items-center gap-x-4'>
        <small>
          <div className='font-semibold text-secondary-foreground'>
            {testimonial.author}
          </div>
          <div className='text-muted-foreground'>{testimonial.role}</div>
        </small>
      </figcaption>
    </figure>
  )
}
