import { LandingHeading } from '@/web/components/LandingHeading'
import { Marquee } from '@/web/components/Marquee'
import { TestimonialCard } from '@/web/components/TestimonialCard'
import type { Testimonial } from '@/web/constants/landing'
import _ from 'lodash'

export const Testimonials = ({
  testimonials,
}: {
  testimonials: Testimonial[]
}) => {
  const testimonialChunks = _.chunk(
    testimonials,
    Math.round(testimonials.length / 2)
  )
  return (
    <div className='bg-white flex flex-col gap-12'>
      <LandingHeading
        title='What Our Users Say'
        subtitle='We’re proud to empower thousands of professionals.'
      />
      {testimonialChunks.map((testimonials, i) => (
        <Marquee reverse={i % 2 === 1} key={i} itemsPerMinute={10}>
          {testimonials.map((testimonial, testimonialIndex) => (
            <TestimonialCard
              key={testimonial.author}
              testimonial={testimonial}
              index={testimonialIndex}
            />
          ))}
        </Marquee>
      ))}
    </div>
  )
}
