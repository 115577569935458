export type Testimonial = {
  body: React.ReactNode
  author: string
  role: string
  company?: string
}

export const GMAIL_TESTIMONIALS: Testimonial[] = [
  {
    body: (
      <>
        Pulse for Gmail has transformed my inbox management.{' '}
        <b>Important tasks no longer get buried under a pile of emails.</b>
      </>
    ),
    author: 'David K.',
    role: 'Consultant',
  },
  {
    body: (
      <>
        The AI-driven prioritization in Pulse means I{' '}
        <b>never miss critical emails.</b> It’s a must-have for HR!
      </>
    ),
    author: 'Nina F.',
    role: 'HR Manager',
  },
  {
    body: (
      <>
        Managing emails is a breeze now.{' '}
        <b>Responding to important messages is so much easier.</b>
      </>
    ),
    author: 'Mark L.',
    role: 'IT Specialist',
  },
  {
    body: (
      <>
        Pulse’s AI-driven insights have{' '}
        <b>made my email workflow super efficient.</b> Can’t work without it
        now.
      </>
    ),
    author: 'Michael B.',
    role: 'Software Engineer',
  },
  {
    body: (
      <>
        The addon is like having a personal assistant.{' '}
        <b>Keeps me organized and ensures I never miss a beat.</b>
      </>
    ),
    author: 'John P.',
    role: 'Business Analyst',
  },
  {
    body: (
      <>
        The clarity and organization this tool brings to Gmail is unmatched.{' '}
        <b>My productivity has shot up.</b>
      </>
    ),
    author: 'Linda M.',
    role: 'Marketing Director',
  },
  {
    body: (
      <>
        Inbox management has never been easier.{' '}
        <b>The design and features of Pulse are a game-changer.</b>
      </>
    ),
    author: 'Chris W.',
    role: 'Operations Manager',
  },
  {
    body: (
      <>
        I’m always on top of my emails with Pulse.{' '}
        <b>It prioritizes my inbox so I can focus on what matters.</b>
      </>
    ),
    author: 'Jessica L.',
    role: 'Sales Executive',
  },
  {
    body: (
      <>
        This addon is incredibly user-friendly.{' '}
        <b>Simplified my email management and boosted my efficiency.</b>
      </>
    ),
    author: 'Peter M.',
    role: 'Content Creator',
  },
  {
    body: (
      <>
        Highlighting important emails has been a lifesaver.{' '}
        <b>My inbox is finally under control.</b>
      </>
    ),
    author: 'Laura K.',
    role: 'Executive Assistant',
  },
  {
    body: (
      <>
        Pulse has revolutionized my workflow!{' '}
        <b>I can track important emails and follow up promptly.</b>
      </>
    ),
    author: 'Emily R.',
    role: 'Customer Support Specialist',
  },
  {
    body: (
      <>
        Total game changer!{' '}
        <b>Pulse has completely transformed the way I handle emails.</b>
      </>
    ),
    author: 'Smith J.',
    role: 'CEO',
  },
  {
    body: (
      <>
        Perfect integration with Gmail.{' '}
        <b>This addon has slashed the time I spend managing emails.</b>
      </>
    ),
    author: 'Brian G.',
    role: 'Financial Analyst',
  },
  {
    body: (
      <>
        Pulse is amazing!{' '}
        <b>It’s been crucial for keeping my inbox organized.</b>
      </>
    ),
    author: 'Thompson E.',
    role: 'Marketing Manager',
  },
  {
    body: (
      <>
        Email management on autopilot.{' '}
        <b>Pulse prioritizes and organizes my inbox automatically.</b>
      </>
    ),
    author: 'Emma H.',
    role: 'Event Coordinator',
  },
  {
    body: (
      <>
        I’ve been using Pulse for a few months and already seeing huge
        improvements. <b>Saved me so much time!</b>
      </>
    ),
    author: 'Sarah K.',
    role: 'Software Engineer',
  },
  {
    body: (
      <>
        Indispensable tool for{' '}
        <b>maintaining a zero inbox and staying organized.</b>
      </>
    ),
    author: 'Tom S.',
    role: 'Entrepreneur',
  },
  {
    body: (
      <>
        Smart filters and categorization in Pulse{' '}
        <b>help me focus on key tasks.</b> My productivity is through the roof!
      </>
    ),
    author: 'Alicia H.',
    role: 'Freelance Writer',
  },
  {
    body: (
      <>
        Smart categorization and reminders mean I{' '}
        <b>never overlook crucial emails.</b> Fantastic tool!
      </>
    ),
    author: 'James T.',
    role: 'Legal Consultant',
  },
  {
    body: (
      <>
        Seamless integration with Gmail.{' '}
        <b>Pulse is essential for optimizing email workflow.</b>
      </>
    ),
    author: 'Sophia J.',
    role: 'Social Media Manager',
  },
  {
    body: (
      <>
        The intuitive design and powerful features{' '}
        <b>make managing my inbox a breeze.</b>
      </>
    ),
    author: 'Sophia W.',
    role: 'Graphic Designer',
  },
  {
    body: (
      <>
        Best thing that has happened to me in years!{' '}
        <b>Pulse changed the way I work and saved me tons of money.</b>
      </>
    ),
    author: 'Emily J.',
    role: 'HR Manager',
  },
  {
    body: (
      <>
        Absolute lifesaver.{' '}
        <b>
          The time I save on email management now goes into more productive
          tasks.
        </b>
      </>
    ),
    author: 'Liam P.',
    role: 'Data Scientist',
  },
  {
    body: (
      <>
        Blown away by how much Pulse has improved my productivity!{' '}
        <b>Can’t recommend it enough!</b>
      </>
    ),
    author: 'Emily T.',
    role: 'Marketing Manager',
  },
  {
    body: (
      <>
        Pulse has streamlined my inbox management.{' '}
        <b>No more missed important emails or deadlines.</b>
      </>
    ),
    author: 'Sam T.',
    role: 'Project Manager',
  },
  {
    body: (
      <>
        Tried other add-ons, but <b>Pulse is the best!</b> Saved me so much time
        and effort. It’s transformed my business.
      </>
    ),
    author: 'Michael R.',
    role: 'IT Manager',
  },
  {
    body: (
      <>
        Game changer! <b>This addon saved me so much time and effort.</b>
      </>
    ),
    author: 'John S.',
    role: 'CEO',
  },
  {
    body: (
      <>
        Pulse is amazing!{' '}
        <b>An indispensable tool for any busy professional.</b>
      </>
    ),
    author: 'Brown E.',
    role: 'HR Specialist',
  },
  {
    body: (
      <>
        Pulse has <b>streamlined my work process and boosted my efficiency.</b>
      </>
    ),
    author: 'Thompson D.',
    role: 'CFO',
  },
  {
    body: (
      <>
        This tool has <b>cut my email management time in half.</b> Now I can
        focus on what really matters.
      </>
    ),
    author: 'Sophie A.',
    role: 'Product Manager',
  },
  {
    body: (
      <>
        Pulse’s email categorization is spot on.{' '}
        <b>It helps me stay organized and prioritize tasks efficiently.</b>
      </>
    ),
    author: 'Greg H.',
    role: 'Technical Lead',
  },
  {
    body: (
      <>
        The best part about Pulse is how it integrates seamlessly with my daily
        workflow. <b>A real time-saver!</b>
      </>
    ),
    author: 'Amanda B.',
    role: 'Marketing Consultant',
  },
  {
    body: (
      <>
        Using Pulse feels like having a personal assistant.{' '}
        <b>It’s always one step ahead in managing my emails.</b>
      </>
    ),
    author: 'Jason L.',
    role: 'Business Development Manager',
  },
  {
    body: (
      <>
        This addon has significantly{' '}
        <b>improved my response time to important emails.</b> Highly recommend!
      </>
    ),
    author: 'Rachel P.',
    role: 'Customer Success Manager',
  },
  {
    body: (
      <>
        Pulse is a lifesaver!{' '}
        <b>It’s made managing my overflowing inbox much easier.</b>
      </>
    ),
    author: 'Tina Q.',
    role: 'Office Manager',
  },
  {
    body: (
      <>
        Love how Pulse keeps me on top of my emails.{' '}
        <b>No more missed follow-ups!</b>
      </>
    ),
    author: 'Eric V.',
    role: 'Sales Director',
  },
  {
    body: (
      <>
        Pulse has made a big difference in how I handle emails.{' '}
        <b>I feel much more in control now.</b>
      </>
    ),
    author: 'Laura N.',
    role: 'Executive Coach',
  },
  {
    body: (
      <>
        Couldn’t imagine going back to my old email management routine.{' '}
        <b>Pulse is a game-changer!</b>
      </>
    ),
    author: 'Patrick W.',
    role: 'Finance Manager',
  },
  {
    body: (
      <>
        Pulse’s user-friendly interface makes it a joy to use.{' '}
        <b>Managing emails has never been this easy.</b>
      </>
    ),
    author: 'Kimberly S.',
    role: 'Graphic Designer',
  },
  {
    body: (
      <>
        I save so much time with Pulse.{' '}
        <b>It’s an essential tool for anyone with a busy inbox.</b>
      </>
    ),
    author: 'Robert J.',
    role: 'Startup Founder',
  },
  {
    body: (
      <>
        Pulse has turned my chaotic inbox into an organized task list.{' '}
        <b>It’s fantastic!</b>
      </>
    ),
    author: 'Jessica F.',
    role: 'PR Specialist',
  },
  {
    body: (
      <>
        This addon helps me quickly identify and prioritize urgent emails.{' '}
        <b>A real efficiency booster.</b>
      </>
    ),
    author: 'Nathan C.',
    role: 'Operations Coordinator',
  },
  {
    body: (
      <>
        Pulse’s smart features ensure I never miss an important email.{' '}
        <b>It’s become an integral part of my day.</b>
      </>
    ),
    author: 'Stephanie M.',
    role: 'Talent Acquisition Specialist',
  },
  {
    body: (
      <>
        Pulse has made juggling multiple email accounts much more manageable.{' '}
        <b>Truly a lifesaver!</b>
      </>
    ),
    author: 'Danielle K.',
    role: 'Virtual Assistant',
  },
  {
    body: (
      <>
        The AI capabilities of Pulse are impressive.{' '}
        <b>It’s like having an extra set of eyes on my inbox.</b>
      </>
    ),
    author: 'Gary B.',
    role: 'Tech Support Specialist',
  },
  {
    body: (
      <>
        This tool has enhanced my productivity by leaps and bounds.{' '}
        <b>Emails are no longer a daunting task.</b>
      </>
    ),
    author: 'Helen T.',
    role: 'Content Strategist',
  },
  {
    body: (
      <>
        Pulse keeps my inbox clutter-free and organized.{' '}
        <b>It’s a must-have for anyone in a busy work environment.</b>
      </>
    ),
    author: 'Alex G.',
    role: 'Product Designer',
  },
  {
    body: (
      <>
        The seamless workflow integration with Gmail makes Pulse indispensable.{' '}
        <b>I can’t imagine work without it.</b>
      </>
    ),
    author: 'Katie H.',
    role: 'Executive Assistant',
  },
  {
    body: (
      <>
        Pulse’s AI is phenomenal.{' '}
        <b>
          It learns and adapts, making my email management smarter and faster.
        </b>
      </>
    ),
    author: 'Ravi P.',
    role: 'Data Analyst',
  },
  {
    body: (
      <>
        Pulse helps me prioritize my tasks effectively.{' '}
        <b>It’s a real game-changer for my daily operations.</b>
      </>
    ),
    author: 'Fiona C.',
    role: 'Operations Manager',
  },
  {
    body: (
      <>
        This addon has taken the hassle out of managing a busy inbox.{' '}
        <b>Highly efficient and user-friendly!</b>
      </>
    ),
    author: 'Lucas M.',
    role: 'Sales Executive',
  },
  {
    body: (
      <>
        The level of automation Pulse provides is outstanding.{' '}
        <b>It’s like having an assistant working round the clock.</b>
      </>
    ),
    author: 'Nadia S.',
    role: 'HR Specialist',
  },
  {
    body: (
      <>
        Pulse has significantly improved my email response time.{' '}
        <b>It’s a critical tool in my workflow.</b>
      </>
    ),
    author: 'Thomas W.',
    role: 'Support Engineer',
  },
  {
    body: (
      <>
        From prioritizing to categorizing, Pulse does it all.{' '}
        <b>My productivity has never been better.</b>
      </>
    ),
    author: 'Isabella D.',
    role: 'Marketing Director',
  },
  {
    body: (
      <>
        This tool has transformed how I manage my inbox.{' '}
        <b>It’s intuitive and very effective.</b>
      </>
    ),
    author: 'Jorge L.',
    role: 'IT Consultant',
  },
  // {
  //   body: (
  //     <>
  //       Pulse’s AI features are top-notch.{' '}
  //       <b>It keeps my inbox neat and ensures I never miss important emails.</b>
  //     </>
  //   ),
  //   author: 'Ella B.',
  //   role: 'Freelance Developer',
  // },
  // {
  //   body: (
  //     <>
  //       Pulse makes email management feel effortless.{' '}
  //       <b>Highly recommended for anyone with a busy schedule.</b>
  //     </>
  //   ),
  //   author: 'Brandon T.',
  //   role: 'Project Lead',
  // },
]

export const REDDIT_TESTIMONIALS: Testimonial[] = [
  {
    body: (
      <>
        Pulse for Reddit has transformed how I manage threads.{' '}
        <b>It’s never been this smooth and efficient.</b>
      </>
    ),
    author: 'Alex D.',
    role: 'Growth Hacker',
  },
  {
    body: (
      <>
        If you’re aiming to excel on Reddit, you need Pulse.{' '}
        <b>It’s like having a 24/7 growth assistant.</b>
      </>
    ),
    author: 'Riley P.',
    role: 'Chief Evangelist Officer',
  },
  {
    body: (
      <>
        I believed I understood Reddit, but Pulse opened a new dimension.{' '}
        <b>
          It handles the heavy lifting, allowing me to focus on scaling my
          startup.
        </b>
      </>
    ),
    author: 'Jordan M.',
    role: 'Startup Founder',
  },
  {
    body: (
      <>
        Pulse has been a true game-changer.{' '}
        <b>
          We’re consistently joining the right conversations, and our engagement
          has skyrocketed.
        </b>
      </>
    ),
    author: 'Taylor S.',
    role: 'Head of Growth',
  },
  {
    body: (
      <>
        Pulse removes the guesswork from Reddit marketing.{' '}
        <b>
          No more hours spent searching for engagement opportunities—it’s a
          lifesaver!
        </b>
      </>
    ),
    author: 'Casey L.',
    role: 'Digital Marketer',
  },
  {
    body: (
      <>
        Pulse is phenomenal!{' '}
        <b>Tracking all my keyword alerts is effortless now.</b>
      </>
    ),
    author: 'Jamie F.',
    role: 'Head of Content',
  },
  {
    body: (
      <>
        After implementing Pulse, our Reddit-driven traffic increased by 50%.{' '}
        <b>It’s like having a dedicated Reddit specialist onboard.</b>
      </>
    ),
    author: 'Morgan T.',
    role: 'Chief Marketing Officer',
  },
  {
    body: (
      <>
        Pulse has revolutionized our Reddit interactions.{' '}
        <b>We no longer juggle threads or miss vital conversations.</b>
      </>
    ),
    author: 'Sam K.',
    role: 'VP of Business Development',
  },
  {
    body: (
      <>
        Without Pulse, you’re missing a critical marketing channel.{' '}
        <b>My Reddit engagements are more effective than ever.</b>
      </>
    ),
    author: 'Jordan H.',
    role: 'Director of User Acquisition',
  },
  {
    body: (
      <>
        Pulse ensures we’re always ahead of Reddit trends.{' '}
        <b>We engage proactively without any fear of missing out.</b>
      </>
    ),
    author: 'Blake N.',
    role: 'Growth Strategist',
  },
  {
    body: (
      <>
        The keyword monitoring feature is top-notch.{' '}
        <b>We never miss the latest discussions in our niche.</b>
      </>
    ),
    author: 'Terry W.',
    role: 'SEO Specialist',
  },
  {
    body: (
      <>
        Pulse has significantly simplified my workload.{' '}
        <b>It’s the assistant I didn’t realize I needed.</b>
      </>
    ),
    author: 'Drew F.',
    role: 'Head of Growth Operations',
  },
  {
    body: (
      <>
        With countless tasks on my plate,{' '}
        <b>Pulse made Reddit engagement effortless, saving me valuable time.</b>
      </>
    ),
    author: 'Alex P.',
    role: 'Entrepreneur-in-Residence',
  },
  {
    body: (
      <>
        Reddit growth was tough before Pulse.{' '}
        <b>Now, we’re part of the right conversations effortlessly.</b>
      </>
    ),
    author: 'Rory E.',
    role: 'Head of Growth and Expansion',
  },
  {
    body: (
      <>
        Pulse acts as my personal Reddit assistant.{' '}
        <b>It tracks, monitors, and enhances my engagement professionally.</b>
      </>
    ),
    author: 'Jamie C.',
    role: 'Chief Growth Officer',
  },
  {
    body: (
      <>
        Reddit felt overwhelming before Pulse.{' '}
        <b>Now, it’s a streamlined channel for growth.</b>
      </>
    ),
    author: 'Dylan M.',
    role: 'Startup Accelerator Manager',
  },
  {
    body: (
      <>
        Pulse scaled up our Reddit engagement.{' '}
        <b>We spend less time and get higher returns—it’s fantastic.</b>
      </>
    ),
    author: 'Chris G.',
    role: 'Growth Marketing Specialist',
  },
  {
    body: (
      <>
        Pulse keeps us ahead by ensuring we’re in the right conversations.{' '}
        <b>It’s vital for startups aiming for rapid growth.</b>
      </>
    ),
    author: 'Pat C.',
    role: 'Venture Partner',
  },
]
